/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.engadget.com/twitter-went-down-for-thousands-of-users-045046684.html"
  }, "Twitter went down for thousands of users | Engadget")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://tapbots.com/tweetbot/"
  }, "Tweetbot. April 2011 - January 2023")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://news.g-enews.com/article/Global-Biz/2023/01/2023010113311611649a1f309431_1?md=20230101135035_U"
  }, "[초점] 트위터 직원들이 '화장실 휴지' 들고 출근 왜? - 글로벌이코노믹")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.businessinsider.com/twitter-blocks-links-to-mastodon-competitor-elon-musk-2022-12"
  }, "Musk's Twitter Is Blocking Links to Its Competitor Mastodon - Business Insider")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/mastodon/mastodon"
  }, "mastodon/mastodon: Your self-hosted, globally interconnected microblogging community - GitHub")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://if.kakao.com/2022/session/111"
  }, "1015 장애 원인 분석 - if(kakao)dev2022")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://zdnet.co.kr/view/?no=20230104175807"
  }, "카카오, 10.15 장애 보고서 공개...이모티콘 보상은 5일 시작 - ZDNet")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://en.wikipedia.org/wiki/ChatGPT"
  }, "ChatGPT - Wikipedia")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://cloud.google.com/vertex-ai"
  }, "Vertex AI | Google Cloud")), "\n");
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
